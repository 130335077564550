import React from "react"
import { Link } from "gatsby"
import More from "../components/wave/more"
import { motion } from "framer-motion"

const transition = { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }

const textVariants = {
  initial: { y: 20, opacity: 0 },
  enter: { y: 0, opacity: 1, transition },
  exit: {
    y: 20,
    opacity: 0,
    transition: { duration: 0.3, ...transition }
  }
}

const SecondPage = () => (
  <motion.div
    initial="initial"
    animate="enter"
    exit="exit"
    variants={{
      exit: { transition: { staggerChildren: 0.1 } },
      enter: { transition: { staggerChildren: 0.1 } }
    }}
  >

    <motion.section
      variants={textVariants}
    >
      <More />
    </motion.section>

  </motion.div>
)

export default SecondPage
